import NewsInfo from "./newsInfo";

export default class ListNews {
  listId: string;
  listName: string;
  newsItems: NewsInfo[];

  constructor(listId: string, listName: string, newsItems: NewsInfo[] = []) {
    this.listId = listId;
    this.listName = listName;
    this.newsItems = newsItems;
  }

  static fromJSON(json: any): ListNews {
    return new ListNews(
      json.listId || '',
      json.listName || '',
      json.newsItems?.map((item: any) => NewsInfo.fromJSON(item)) || []
    );
  }
}
