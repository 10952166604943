import ItemInfo from "./itemInfo";

export default class ProfitDotComTickerQuote {
  ticker: string;
  name: string;
  symbol: string;
  price: number;
  previous_close: number;
  daily_price_change: number;
  daily_percentage_change: number;
  timestamp: number;
  asset_class: string;
  currency: string;
  logo_url: string;
  volume: number;
  broker: string;
  ohlc_week: {
    open: number;
    high: number;
    low: number;
    close: number;
  };

  static toItemInfo(quoteData: ProfitDotComTickerQuote) {
    const itemInfo = new ItemInfo(quoteData.name, quoteData.symbol, quoteData.name);
    
    return {
      name: quoteData.name,
      symbol: quoteData.symbol,
      description: quoteData.name,
      price: quoteData.price,
      changesPercentage: quoteData.daily_percentage_change,
      volume: quoteData.volume,
      createdOn: new Date(),
      modifiedOn: new Date(),
    };
  }
} 