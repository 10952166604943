import ArticleInfo from "./articleInfo";

export default class NewsInfo {
  id?: string;
  itemId?: string;
  long?:string;
  short?:string;

  symbol?: string;
  articles?: ArticleInfo[];
  isArticlesUpdated?: boolean;
  createdOn?: Date;
  modifiedOn?: Date;
  refreshedOnL?: number;

  constructor(id: string, itemId: string) {
    this.id = id;
    this.itemId = itemId;
  }

  static create(id: string, data: any): NewsInfo {
    const newsItem = new NewsInfo(id, data.itemId || '');
    Object.assign(newsItem, {
      long: data.long,
      short: data.short,
      symbol: data.symbol,
      articles: data.articles,
      isArticlesUpdated: data.isArticlesUpdated,
      createdOn: data.createdOn,
      modifiedOn: data.modifiedOn,
      refreshedOnL: data.refreshedOnL
    });
    return newsItem;
  }

  static fromJSON(json: any): NewsInfo {
    const newsItem = new NewsInfo(json.id || '', json.itemId || '');
    Object.assign(newsItem, {
      long: json.long,
      short: json.short,
      symbol: json.symbol,
      articles: json.articles?.map((article: any) => ArticleInfo.fromJSON(article)),
      isArticlesUpdated: json.isArticlesUpdated,
      createdOn: json.createdOn ? new Date(json.createdOn) : undefined,
      modifiedOn: json.modifiedOn ? new Date(json.modifiedOn) : undefined,
      refreshedOnL: json.refreshedOnL
    });
    return newsItem;
  }
}
