import SecRssFeedItem from "@entities/secRssFeedItem";
import {PropsWithChildren, ReactElement, useState} from 'react';
import NewsLetterItemList from "@/components/news-letter-item-list";
import contentStyles from "@/styles/content.module.css";
import headerStyles from "@/styles/header.module.css";
import InTheNewsItem from "@/components/in-the-news-item";

interface NewsLetterContentWrapperProperties {
  secInTheNews: SecRssFeedItem[];
  listIds: {
    id: string;
    caption: string;
  }[];
}

function NewsLetterContentWrapper(props: PropsWithChildren<NewsLetterContentWrapperProperties>):ReactElement {
  const [inTheNews, setInTheNews] = useState<SecRssFeedItem[]>(props.secInTheNews || []);

  return (
    <div className="flex-1 overflow-hidden">
      <div className="h-full overflow-y-auto px-4 py-6 space-y-6 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent">
        <div className="flex flex-col sm:flex-row w-full gap-6">
          {props.listIds.map((list, index) => (
            <div key={list.id} className="w-full sm:w-1/3">
              <NewsLetterItemList listId={list.id} caption={list.caption} />
            </div>
          ))}
          <div className={`w-full sm:w-1/3 ${contentStyles.stockItemWall}`}>
            <div className={headerStyles.sectionHeader}>IN THE NEWS</div>
            {inTheNews.map((item: SecRssFeedItem) => (
              <InTheNewsItem item={item} key={item.title} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsLetterContentWrapper;
