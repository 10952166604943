import {PropsWithChildren, ReactElement, useEffect, useState} from 'react';
import {useFirebaseService} from "@/hooks/useFirebaseService";
import NewsInfo from "@entities/newsInfo";
import ItemInfo from "@entities/itemInfo";
import ProgressIndicator from './progress-indicator';

interface NewsLetterItemExProperties {
  itemInfo?: ItemInfo;
}

const truncateText = (text: string, limit: number) => {
  if (text.length > limit) {
    return text.substring(0, limit) + "...";
  } else {
    return text;
  }
};

function NewsLetterItemEx(properties: PropsWithChildren<NewsLetterItemExProperties>): ReactElement {
  const {getNewsItemSnapshot} = useFirebaseService();
  const [newsInfo, setNewsInfo] = useState<any|null>(null);
  const [itemInfo, setItemInfo] = useState<ItemInfo|null>(properties?.itemInfo || null);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(0);

  useEffect(() => {
    if(!itemInfo || !itemInfo.symbol || itemInfo.symbol.length == 0) {
      setIsLoading(false);
      return;
    }
    
    setIsLoading(true);
    // Simulate progress for better UX
    const progressInterval = setInterval(() => {
      setLoadingProgress(prev => {
        if (prev >= 90) {
          clearInterval(progressInterval);
          return prev;
        }
        return prev + 10;
      });
    }, 100);

    const unsubscribe = getNewsItemSnapshot(itemInfo.symbol, handleData, handleError);
    
    return () => {
      unsubscribe();
      clearInterval(progressInterval);
      setLoadingProgress(0);
    };
  }, [itemInfo]);

  const handleData = (data: any) => {
    setNewsInfo(data as NewsInfo);
    setLoadingProgress(100);
    setTimeout(() => setIsLoading(false), 200); // Smooth transition
  }

  const handleError = (error: any) => {
    console.log("Error getting document data:", error);
    setIsLoading(false);
    setLoadingProgress(0);
  }

  return (
    <div className="flex flex-col items-center justify-center w-full bg-white mb-4 rounded-2xl border border-gray-200/10 shadow-sm hover:shadow-md transition-shadow duration-200 relative overflow-hidden">
      <ProgressIndicator isLoading={isLoading} loadingProgress={loadingProgress} />
      <div className="font-sans text-sm text-center text-gray-500 p-3 border-b border-gray-100 w-full flex flex-col justify-start"
        hidden={true}>
        <div className="flex flex-col justify-center text-xs w-full">
          <div className="font-medium text-gray-900">{itemInfo?.symbol || 'NA'}</div>
          <div className="font-sans text-sm text-gray-500 w-full flex flex-col sm:flex-row items-start sm:items-center justify-between gap-3 mt-1">
            <div className="flex-1 min-w-0 text-left">{itemInfo?.name || 'NA'}</div>
            <div className="border border-gray-200 rounded-full px-3 py-1.5 flex-shrink-0 text-right whitespace-nowrap bg-gray-50">
              <span className="text-gray-900 font-medium">${Number(itemInfo?.price).toFixed(2)}</span>{' '}
              <span className={`${Number(itemInfo?.changesPercentage) >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                ({Number(itemInfo?.changesPercentage).toFixed(2)}%)
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="text-[15px] leading-relaxed text-gray-600 font-sans p-4 min-h-[100px] w-full overflow-auto break-words scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-transparent">
        <div className="max-h-[200px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-transparent">
          {newsInfo?.short || 'No Description'}
        </div>
      </div>
    </div>
  );
}

export default NewsLetterItemEx;
