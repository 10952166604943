"use client";

import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import {
  signInAnonymously,
  onAuthStateChanged,
  signOut,
  setPersistence,
  browserLocalPersistence,
  UserCredential,
  User,
  AuthError
} from "firebase/auth";

import { useRouter } from "next/navigation";
import UserInfo2 from "@entities/userInfo2";
// import useFirestore from '@/hooks/useFirestore';
import { Unsubscribe } from "@firebase/firestore";
import { auth } from "@/hooks/firebase.client.config";
import { useSignInWithEmailAndPassword, useCreateUserWithEmailAndPassword } from 'react-firebase-hooks/auth';

/**
 * Interface that will be available to all the enclosed components
 */
interface AuthContextType {
  userInfo: UserInfo2 | null;
  logOut: () => void;
  anonymousSignIn: () => void;
  handleSignInSuccess: (authResult: UserCredential, redirectUrl: string) => Promise<boolean>;
  handleSignInFailure: (error: any) => Promise<void>;
  setUserInfo: React.Dispatch<React.SetStateAction<UserInfo2 | null>>;
  getUserInfo: () => UserInfo2 | null;
  checkNoAnonymousAllowed: () => boolean;
  checkNoAnonymousAllowedWithReDirect: (redirectPath: string) => boolean;
  signUpWithEmailAndPassword: (email: string, password: string) => Promise<UserCredential|null>;
  signInWithEmailAndPassword: (email: string, password: string) => Promise<UserCredential|null>;
  signInLoading: boolean;
  signInError: AuthError | undefined;
  createUserLoading: boolean;
  createUserError: AuthError | undefined;
}

/**
 * Create the context
 */
const AuthContext = createContext<AuthContextType | null>(null);
/**
 * Create the provider
 */
export const AuthContextProvider = (props: PropsWithChildren) => {
  const [userInfo, setUserInfo] = useState<UserInfo2 | null>(null);
  const router = useRouter();
//   const fireStore = useFirestore();
  const [
    signInWithEmailAndPasswordHook,
    useCredSignIn,
    signInLoading,
    signInError
  ] = useSignInWithEmailAndPassword(auth);

  const [
    createUserWithEmailAndPasswordHook,
    userCredSignUp,
    createUserLoading,
    createUserError
  ] = useCreateUserWithEmailAndPassword(auth);

  useEffect(() => {
    let unsubscribe: Unsubscribe | null = null;

    // if (auth) {
    //   setPersistence(auth, browserLocalPersistence)
    //     .then(() => {
    //       unsubscribe = onAuthStateChanged(auth, (user) => {
    //         setUserInfo(user ? new UserInfo2(user) : null);
    //       });
    //     })
    //     .catch((error) => {
    //       // Handle Errors here.
    //       console.log(`Error setting persistence:`, error);
    //     });
    // }

    // Cleanup subscription on unmount
    // return () => {
    //   if (unsubscribe) {
    //     unsubscribe();
    //   }
    // }
  }, []);

  function updateUserInfo(userInfo: UserInfo2 | null = null) {
    if (userInfo) {
      ((newUserInfo: UserInfo2 | null) => {
        setUserInfo(newUserInfo);
      })(userInfo);
    } else {
      console.log("==========================================");
      console.log(`Setting userInfo to NULL...`);
      console.log("==========================================");
      ((newUserInfo: UserInfo2 | null) => {
        setUserInfo(newUserInfo);
      })(null);
    }
  }

  const anonymousSignIn = async () => {
    const result = await signInAnonymously(auth);
    // setRawUser(result.user);
    console.log(`Anonymous SignIn result:`, result);
    // Store the anonymous user info
    const anonymousUserInfo = new UserInfo2(result.user);
    setUserInfo(anonymousUserInfo);
    localStorage.setItem('userInfo', JSON.stringify(anonymousUserInfo));
  }

  const handleSignInSuccess = async (authResult: UserCredential, redirectUrl: string): Promise<boolean> => {
    let result = false;

    try {
      console.log('Handle SignIn Success Called');
      const userInfo: UserInfo2 = new UserInfo2(authResult.user);
      setUserInfo(userInfo);
    //   await fireStore.saveUserRemote(userInfo);
    //   const remoteUser = await fireStore.getUser(userInfo.uid);
      
    //   if (remoteUser) {
    //     setUserInfo(remoteUser);
    //     router.push(redirectUrl);
    //     result = true;
    //   }
    } catch (error) {
      console.error('Error in handleSignInSuccess:', error);
      await handleSignInFailure(error);
    }

    return result;
  }

  const handleSignInFailure = async (error: any): Promise<void> => {
    // Handle sign-in failure here...
    // await signOut(auth);
    await logOut();
  };

  const logOut = async () => {
    try {
      await signOut(auth);
      updateUserInfo(null);
      router.push('/signin');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  }

  const getUserInfo = (): UserInfo2 | null => userInfo;

  const checkNoAnonymousAllowed = (): boolean => {
    const userInfo = getUserInfo();
    const isInvalidOrAnonymous = !userInfo || (userInfo && userInfo.isAnonymous);
    if (isInvalidOrAnonymous) {
      router.push(`/signin`);
    }
    return isInvalidOrAnonymous;
  }

  const checkNoAnonymousAllowedWithReDirect = (redirectPath: string): boolean => {
    const userInfo = getUserInfo();
    const isInvalidOrAnonymous = !userInfo || userInfo.isAnonymous;
    if (isInvalidOrAnonymous) {
      router.push(`/signin/${encodeURIComponent(redirectPath)}`);
    }
    return isInvalidOrAnonymous;
  }

  const signUpWithEmailAndPassword = async (email: string, password: string) : Promise<UserCredential|null> => {
    return await createUserWithEmailAndPasswordHook(email, password) || null;
  }

  const signInWithEmailAndPassword = async (email: string, password: string) : Promise<UserCredential|null> => {
    try {
      const result = await signInWithEmailAndPasswordHook(email, password);
      if (!result) {
        throw new Error('Sign in failed');
      }
      // console.log("Sign In Result: ", result);
      // await handleSignInSuccess(result, '/');
      return result;
    } catch (error: any) {
      // await handleSignInFailure(error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        userInfo,
        logOut,
        anonymousSignIn,
        handleSignInSuccess,
        handleSignInFailure,
        setUserInfo,
        getUserInfo,
        checkNoAnonymousAllowed,
        checkNoAnonymousAllowedWithReDirect,
        signUpWithEmailAndPassword,
        signInWithEmailAndPassword,
        signInLoading,
        signInError,
        createUserLoading,
        createUserError
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

/**
 * Custom hook to use the AuthContext
 */
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthContextProvider");
  }
  return context;
};
