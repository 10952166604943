'use client';

import { useState, useEffect, useCallback } from "react";
import dynamic from 'next/dynamic';
import CachingService from "@services/caching.service";
import SecRssFeedItem from "@entities/secRssFeedItem";
import { useFirebaseService } from "@/hooks/useFirebaseService";
import HeaderContainer from "@/components/header-container";
import RssAggregatorService from "@services/rssAggregator.service";
import NewsLetterContentWrapper from "@/components/newsletter-content-wrapper";

// Dynamically import components that might use browser APIs
const Footer = dynamic(() => import('@/components/footer'), { ssr: false });

export default function Home() {
  const keyInTheNews = "data-in-the-news";
  const [news, setNews] = useState<SecRssFeedItem[]>([]);
  const [loading, setLoading] = useState(true);
  const { addNews, getNewsDoc, getVisitors } = useFirebaseService();
  const [latestNews, setLatestNews] = useState<any[]>([]);

  // useEffect(() => {
  //   const fetchVisitors = async () => {
  //     try {
  //       if(!getVisitors) {
  //         return;
  //       }
  //       const visitors = await getVisitors();
  //       if (visitors) {
  //         console.log('Visitors:', visitors);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching visitors:', error);
  //     }
  //   };

  //   fetchVisitors();

  // }, [getVisitors]);

  const fetchInTheNews = useCallback(async () => {
    try {
      let cachedInTheNews = CachingService.getCache().get(keyInTheNews);
      let secInTheNews: SecRssFeedItem[] = [];
      
      if (cachedInTheNews && cachedInTheNews.length > 0) {
        secInTheNews = cachedInTheNews;
      } else {
        let rssAggregator = new RssAggregatorService();
        secInTheNews = await rssAggregator.getRssFeed();
        if (secInTheNews && secInTheNews.length > 0) {
          CachingService.getCache().set(keyInTheNews, secInTheNews);
        }
      }
      
      console.log("Fetched SEC news, count:", secInTheNews.length);
      setNews(secInTheNews);
    } catch (error) {
      console.error('Error fetching SEC news:', error);
    } finally {
      setLoading(false);
    }
  }, [keyInTheNews]);

  useEffect(() => {
    fetchInTheNews();
  }, [fetchInTheNews]);

  // Define listIds array that will be shared between components
  const listIds = [
    { id: 'TOP_LEADERS', caption: 'TOP LEADERS' }
    , { id: 'TOP_LOSERS', caption: 'TOP LOSERS' }
  ];

  if (loading) {
    return <div>Loading news...</div>;
  }

  return (
    <div className="flex flex-col min-h-screen">
      {/* Fixed Header */}
      <HeaderContainer listIds={listIds} />
      {/* Scrollable Content */}
      <main className="flex-grow bg-gray-50">
        <NewsLetterContentWrapper listIds={listIds} secInTheNews={news} />
      </main>
      {/* Fixed Footer */}
      <Footer />
    </div>
  );
}