import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
  documentId,
  QueryDocumentSnapshot,
  Unsubscribe
} from "@firebase/firestore"
import { db } from "./firebase.client.config"

const COLL_ITEMS = "items"
const BATCH_SIZE = 10

function docSnapshotToDataWithId(docSnap: QueryDocumentSnapshot) {
  return {
    id: docSnap.id,
    ...docSnap.data()
  }
}

function querySnapshotToDataWithId(qSnapshot: any) {
  return (qSnapshot.docs || []).map((doc: any) => docSnapshotToDataWithId(doc))
}

export const getItemsSnapshot = (
  items: string[], 
  handleData: (doc: any) => void, 
  handleError: (error: any) => void
): Unsubscribe => {
  if (!items || items.length === 0) {
    handleError(new Error('No IDs provided'))
    return () => {}
  }

  const collectionRef = collection(db, COLL_ITEMS)

  if (items.length <= BATCH_SIZE) {
    const docQuery = query(collectionRef, where(documentId(), 'in', items))
    return onSnapshot(
      docQuery,
      {
        next: (snapshot) => handleData(querySnapshotToDataWithId(snapshot)),
        error: handleError
      }
    )
  }

  console.log("processing in batches...")
  const batches = []
  const batchResults = new Map<number, any[]>()
  
  for (let i = 0; i < items.length; i += BATCH_SIZE) {
    const batchIds = items.slice(i, i + BATCH_SIZE)
    console.log(`Processing batch ${i/BATCH_SIZE}: `, batchIds)
    batches.push({
      index: i/BATCH_SIZE,
      query: query(collectionRef, where(documentId(), 'in', batchIds))
    })
  }

  const unsubscribes = batches.map(({index, query: batchQuery}) =>
    onSnapshot(
      batchQuery,
      {
        next: (snapshot) => {
          const batchData = querySnapshotToDataWithId(snapshot)
          batchResults.set(index, batchData)
          
          // Check if we have all batch results
          if (batchResults.size === batches.length) {
            const allResults = Array.from(batchResults.entries())
              .sort(([a], [b]) => a - b)
              .flatMap(([_, data]) => data)
            handleData(allResults)
          }
        },
        error: handleError
      }
    )
  )

  return () => unsubscribes.forEach(unsubscribe => unsubscribe())
} 