import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"

/**
 * Created by shadcn
 * @see https://github.com/shadcn/tailwindcss-classnames
 * @param inputs - An array of class names to combine.
 * @returns A string of class names with all duplicates removed and merged.
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}
