export default class ArticleInfo {
  source: string;
  title: string;
  description: string;
  url: string;
  published_at?: any;
  score: {
    neg: number;
    neu: number;
    pos: number;
    compound: number;
  };

  static fromJSON(json: any): ArticleInfo {
    const article = new ArticleInfo();
    Object.assign(article, {
      source: json.source,
      title: json.title,
      description: json.description,
      url: json.url,
      published_at: json.published_at,
      score: {
        neg: json.score?.neg || 0,
        neu: json.score?.neu || 0,
        pos: json.score?.pos || 0,
        compound: json.score?.compound || 0
      }
    });
    return article;
  }
}
