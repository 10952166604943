export default class ItemInfo {
  id?: string;
  name: string;
  symbol: string;
  description?: string;
  change?: number;
  price?: number;
  changesPercentage?: number;
  createdOn?: Date;
  modifiedOn?: Date;
  refreshNews?: boolean;
  lastRefreshedOnL?: number;
  message?: string;
  constructor(name: string, symbol: string, description: string, id?: string) {
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.description = description;
  }
}
