import {PropsWithChildren, ReactElement, useState} from 'react';
import SecRssFeedItem from "@entities/secRssFeedItem";

interface InTheNewsItemProperties {
  item: SecRssFeedItem;
}

function InTheNewsItem(properties: PropsWithChildren<InTheNewsItemProperties>):ReactElement{
  const [item, setItem] = useState(properties.item)
  return <div className="flex flex-col items-center justify-center w-full max-h-48 bg-gray-100 mb-2">
    <div className="text-sm font-extralight font-serif p-2 min-h-[100px] w-full">
      <div className="font-bold mb-2">{item.title}</div>
      <div className="text-sm leading-6">{item.description}</div>
    </div>
  </div>
}

export default InTheNewsItem;
