"use client"
import {
  addDoc,
  collection,
  doc,
  getDoc,
  DocumentReference,
  getDocs,
  limit,
  onSnapshot,
  query,
  QueryDocumentSnapshot,
  Unsubscribe,
  where,
  documentId,
  setDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  serverTimestamp,
  deleteDoc
} from "@firebase/firestore"

import { useEffect, useState } from "react"
import ListNews from "@entities/listNews"
import NewsInfo from "@entities/newsInfo"
import ItemInfo from "@entities/itemInfo"
import ProfitDotComTickerQuote from '@entities/profitDotComTickerQuote'
import { getItemsSnapshot as getItemsSnapshotService } from './firebase.client.service'
import ListInfo from "@/functions/src/entities/listInfo"
import { app, db } from "@/hooks/firebase.client.config"

export function useFirebaseService() {
  const [isInitialized, setIsInitialized] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    try {
      // Check if Firebase app is available
      if (!app?.options) {
        setError(new Error('Firebase app is not properly configured'))
        return
      }

      // Check if Firestore is available
      if (!db) {
        setError(new Error('Firestore is not properly configured'))
        return
      }

      // If we get here, Firebase is properly initialized
      setIsInitialized(true)
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Firebase initialization failed'))
    }
  }, [])

  // if (!isInitialized) {
  //   return {
  //     isInitialized,
  //     error,
  //     getItemDoc: () => null,
  //     getNewsDoc: async () => null,
  //     getNewsItemSnapshot: () => () => null,
  //     getItemsSnapshot: () => () => null,
  //     getListItem: async () => null,
  //     addNews: async () => null,
  //     updateVisitorCount: async () => {},
  //     getVisitorCountSnapshot: () => () => null,
  //     getListNewsInfo: async () => new ListNews('', '', []),
  //     getMultipleListsNewsInfo: async () => [],
  //     getListData: async () => ({}),
  //     cleanup: async () => {},
  //     addItemToWatchlist: async () => {},
  //     getListsForUser: async () => [],
  //     addUserList: async () => '',
  //     getListInfo: async () => null,
  //     deleteList: async () => {},
  //     refreshLists: async () => {},
  //     getVisitors: async () => {},
  //   }
  // }

  const COLL_NEWS = "news"
  const COLL_ITEMS = "items"
  const COLL_LISTS = "lists"
  const COLL_VISITORS = "visitors"
  const COLL_USERS = "users"
  const BATCH_SIZE = 10

  function docSnapshotToDataWithId(docSnap: QueryDocumentSnapshot) {
    return {
      id: docSnap.id,
      ...docSnap.data()
    }
  }

  function querySnapshotToDataWithId(qSnapshot: any) {
    return (qSnapshot.docs || []).map((doc: any) => docSnapshotToDataWithId(doc));
  }

  const getItemDoc = (id: string) => {
    if(!id) return null;
    return doc(db, COLL_ITEMS, id);
  }

  const getNewsDoc = async (symbol: string) => {
    const docs = await getDocumentsByField(COLL_NEWS, "symbol", symbol);
    return docs.length > 0 ? docs[0] : null;
  }

  const getDocumentsByField = async (collectionName: string, field: string, value: any): Promise<any[]> => {
    let qry = query(collection(db, collectionName), where(field, "==", value));
    const docs = (await getDocs(qry)).docs;
    return docs.map(doc => doc.data());
  };

  const getNewsItemSnapshot = (
    symbol: string,
    onData: (data: any) => void,
    onError: (error: any) => void
  ): Unsubscribe => {
    if (!symbol) {
      console.error('Symbol is required for getNewsItemSnapshot');
      return () => {};
    }

    console.log('getNewsItemSnapshot called for symbol:', symbol);
    const newsRef = doc(db, COLL_NEWS, symbol.toUpperCase());
    return onSnapshot(
      newsRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.data();
          console.log(`News data for ${symbol}:`, data);
          if (data) {
            onData({
              id: snapshot.id,
              symbol: symbol.toUpperCase(),
              ...data
            });
          } else {
            onData(createDefaultNewsItem(symbol, symbol));
          }
        } else {
          onData(createDefaultNewsItem(symbol, symbol));
        }
      },
      onError
    );
  }

  const getItemsSnapshot = (items:string[], handleData: (doc: any) => void, handleError: (error: any) => void): Unsubscribe => {
    console.log('getItemsSnapshot called for items:', items);
    return getItemsSnapshotService(items, handleData, handleError)
  }

  // const getListItemx = async (listId: string): Promise<ListInfo | null> => {
  //   try {
  //     console.log('getListItem called for listId:', listId);
  //     if (!db) throw new Error('Firebase is not configured')
      
  //     const listRef = doc(db, COLL_LISTS, listId);
  //     const listDoc = await getDoc(listRef);
      
  //     if (!listDoc.exists()) return null;

  //     const data = listDoc.data();
  //     return new ListInfo(
  //       listDoc.id,
  //       data.name || listDoc.id,
  //       data.items || []
  //     );
  //   } catch (error) {
  //     console.error('Error in getListItem:', error);
  //     throw error;
  //   }
  // }

  const addNews = async (): Promise<DocumentReference | null> => {
    try {
      const docRef = await addDoc(collection(db, COLL_NEWS), {
        name: "Tokyo",
        country: "Japan"
      });

      console.log("Document written with ID: ", docRef.id);
      return docRef;

    } catch (error) {
      console.error("Error adding document: ", error);
      return null;
    }
  }

  const updateVisitorCount = async (ip: string): Promise<void> => {
    const today = new Date().toISOString().split('T')[0]; 
    const visitorRef = doc(db, COLL_VISITORS, today);

    try {
      const docSnap = await getDoc(visitorRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        if (!data.ips.includes(ip)) {
          await updateDoc(visitorRef, {
            count: data.count + 1,
            ips: arrayUnion(ip)
          });
        }
      } else {
        await setDoc(visitorRef, {
          date: today,
          count: 1,
          ips: [ip]
        });
      }
    } catch (error) {
      console.error("Error updating visitor count:", error);
    }
  };

  const getVisitorCountSnapshot = (
    onData: (count: number) => void,
    onError: (error: Error) => void
  ): Unsubscribe => {
    const today = new Date().toISOString().split('T')[0]; 
    const visitorRef = doc(db, COLL_VISITORS, today);

    return onSnapshot(
      visitorRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.data();
          onData(data.count || 0);
        } else {
          onData(0);
        }
      },
      onError
    );
  };

  async function getListData(listId: string): Promise<any> {
    const listRef = doc(db, COLL_LISTS, listId);
    const listDoc = await getDoc(listRef);
    return listDoc.data() || {};
  }

  async function getListNewsInfo(listId: string): Promise<ListNews> {
    const defaultList = [createDefaultNewsItem()];

    try {
      // Step 1: Get list data
      const listData = await getListData(listId);
      const itemIds = listData.items || [];
      const listName = listData.name || "";

      // If no items in list, return early with default
      if (!itemIds.length) {
        return new ListNews(listId, listName, defaultList);
      }

      // Step 2: Get item information for all items
      const itemInfos: ItemInfo[] = [];
      try {
        const itemsSnapshot = await getDocs(
          query(collection(db, COLL_ITEMS), 
          where(documentId(), 'in', itemIds))
        );
        
        itemsSnapshot.forEach(doc => {
          const data = doc.data();
          if (data && data.symbol) {  // Only add items with valid symbols
            itemInfos.push(data as ItemInfo);
          }
        });
      } catch (error) {
        console.error('Error fetching item information:', error);
        return new ListNews(listId, listName, defaultList);
      }

      // If no valid items found, return early with default
      if (!itemInfos.length) {
        return new ListNews(listId, listName, defaultList);
      }

      // Step 3: Extract and filter valid symbols
      const symbols = itemInfos
        .map(item => item.symbol)
        .filter((symbol): symbol is string => !!symbol);

      if (!symbols.length) {
        return new ListNews(listId, listName, defaultList);
      }

      // Step 4: Get news items for all symbols
      let newsItems: NewsInfo[] = [];
      try {
        const newsSnapshot = await getDocs(
          query(collection(db, COLL_NEWS), 
          where(documentId(), 'in', symbols))
        );

        newsItems = newsSnapshot.docs
          .map(doc => NewsInfo.create(doc.id, doc.data()))
          .filter(item => item.long || item.short); // Only include items with content
      } catch (error) {
        console.error('Error fetching news items:', error);
        return new ListNews(listId, listName, defaultList);
      }

      // Return with default list if no valid news items found
      return new ListNews(
        listId, 
        listName, 
        newsItems.length ? newsItems : defaultList
      );

    } catch (error) {
      console.error('Error in getListNewsInfo:', error);
      return new ListNews(listId, "", []);
    }
  }

  const getMultipleListsNewsInfo = async (listIds: string[]): Promise<ListNews[]> => {
    const results: ListNews[] = [];
    for (const listId of listIds) {
      const listNews = await getListNewsInfo(listId);
      results.push(listNews);
    }
    return results;
  }

  const getListsForUser = async (userId: string): Promise<string[]> => {
    try {
      console.log('getListsForUser called for userId:', userId);
      if (!db) throw new Error('Firebase is not configured')

      const userRef = doc(db, COLL_USERS, userId)
      const userDoc = await getDoc(userRef)
      
      if (!userDoc.exists()) {
        console.log('User document not found, creating new one');
        // Create user document if it doesn't exist
        await setDoc(userRef, { lists: [] })
        return []
      }

      const userData = userDoc.data()
      console.log('User data retrieved:', userData);
      return userData?.lists || []
    } catch (error) {
      console.error('Error in getListsForUser:', error)
      throw error
    }
  }

  const addUserList = async (userId: string, listName: string): Promise<string> => {
    if (!listName.trim()) {
      throw new Error("List name cannot be empty")
    }

    try {
      // Create the new list
      const newList = {
        name: listName.trim(),
        items: [],
        userId,
        createdOn: serverTimestamp()
      }
      const listRef = await addDoc(collection(db, COLL_LISTS), newList)

      // Add the list reference to the user's lists
      const userRef = doc(db, 'users', userId)
      await updateDoc(userRef, {
        lists: arrayUnion(listRef.id)
      })

      return listRef.id
    } catch (error) {
      console.error('Error adding list:', error)
      throw error
    }
  }

  const deleteList = async (userId: string, listId: string): Promise<void> => {
    if (!listId) {
      throw new Error("List ID is required")
    }

    try {
      // First, remove the list from user's lists array
      const userRef = doc(db, COLL_USERS, userId)
      await updateDoc(userRef, {
        lists: arrayRemove(listId)
      })

      // Then delete the list document
      const listRef = doc(db, COLL_LISTS, listId)
      await deleteDoc(listRef)
    } catch (error) {
      console.error('Error deleting list:', error)
      throw error
    }
  }

  function createDefaultNewsItem(itemId: string = 'default', symbol: string = ''): NewsInfo {
    const now = new Date();
    const newsItem = new NewsInfo(itemId, itemId);
    newsItem.symbol = symbol;
    newsItem.long = `No News for: ${symbol || 'Unknown Ticker'}`;
    newsItem.short = `No News for: ${symbol || 'Unknown Ticker'}`;
    newsItem.articles = [];
    newsItem.isArticlesUpdated = false;
    newsItem.createdOn = now;
    newsItem.modifiedOn = now;
    newsItem.refreshedOnL = now.getTime();
    return newsItem;
  }

  const cleanup = async () => {
    // Add any cleanup logic here if needed
    // This is a no-op for now since we're using one-time fetches
    return Promise.resolve();
  }

  const addItemToWatchlist = async (listId: string, quoteData: ProfitDotComTickerQuote): Promise<void> => {
    try {
      if (!db) throw new Error('Firebase is not configured')
      if (!quoteData?.symbol) throw new Error('Symbol is required')
      if (!listId) throw new Error('List ID is required')

      const itemsRef = collection(db, COLL_ITEMS)
      const itemQuery = query(itemsRef, where('symbol', '==', quoteData.symbol.toUpperCase()))
      const existingItems = await getDocs(itemQuery)
      
      let itemId: string
      const itemToSave = ProfitDotComTickerQuote.toItemInfo(quoteData)
      const now = new Date()

      console.log("==========================================");
      console.log(`Quote Data: ${JSON.stringify(quoteData)}`);
      console.log("==========================================");

      if (existingItems.empty) {
        const newItemRef = await addDoc(itemsRef, {
          ...itemToSave,
          createdOn: now
        })
        itemId = newItemRef.id
      } else {
        itemId = existingItems.docs[0].id
        await updateDoc(doc(db, COLL_ITEMS, itemId), {
          ...itemToSave,
          modifiedOn: now
        })
      }

      // Now update the list with the new item
      const listRef = doc(db, COLL_LISTS, listId)
      const listDoc = await getDoc(listRef)

      console.log("==========================================");
      console.log(`List ${listId} exists:`, listDoc.exists());
      console.log(`List data:`, listDoc.data());
      console.log("==========================================");

      if (!listDoc.exists()) {
        // Create the list if it doesn't exist
        await setDoc(listRef, {
          name: listId,
          items: [],
          createdOn: new Date(),
          modifiedOn: new Date()
        });
      }

      const currentItems = listDoc.data()?.items || []
      
      if (currentItems.includes(itemId)) {
        throw new Error(`${quoteData.symbol} is already in this watchlist`)
      }

      await updateDoc(listRef, {
        items: arrayUnion(itemId),
        modifiedOn: now,
      })
    } catch (error) {
      console.error('Error adding item to watchlist:', error)
      throw error
    }
  }

  const getListItem = async (listId: string): Promise<ListInfo | null> => {
    try {
      const listRef = doc(db, COLL_LISTS, listId)
      const listDoc = await getDoc(listRef)
      return listDoc.exists() ? listDoc.data() as ListInfo : null
    } catch (error) {
      console.error('Error fetching list item:', error)
      throw error
    }
  }

  const getVisitors = async () => {
    try {
      const visitorsRef = doc(db, COLL_VISITORS, "2024-11-25");
      const visitorsDoc = await getDoc(visitorsRef)
      return visitorsDoc.exists() ? visitorsDoc.data() : {}
    } catch (error) {
      console.error('Error fetching visitors:', error)
      throw error
    }
  }

  return {
    isInitialized,
    error,
    getItemDoc,
    getNewsDoc,
    getNewsItemSnapshot,
    getItemsSnapshot,
    getListItem,
    addNews,
    updateVisitorCount,
    getVisitorCountSnapshot,
    getListNewsInfo,
    getMultipleListsNewsInfo,
    getListData,
    cleanup,
    addItemToWatchlist,
    getListsForUser,
    addUserList,
    deleteList,
    getVisitors
  }
}
