import React, {PropsWithChildren, ReactElement, useEffect, useState} from 'react';
import contentStyles from "@/styles/content.module.css";
import headerStyles from "@/styles/header.module.css";
import NewsLetterItemEx from "@/components/news-letter-itemex";
import {useFirebaseService} from "@/hooks/useFirebaseService";
import ListInfo from "@entities/listInfo";
import ItemInfo from '@entities/itemInfo';

interface NewsLetterItemListProperties {
  listId: string;
  caption: string;
}

function NewsLetterItemList(properties: PropsWithChildren<NewsLetterItemListProperties>):ReactElement {
  const {getListItem, getItemsSnapshot} = useFirebaseService();
  const [items, setItems] = useState<ItemInfo[]>([]);
  const [listInfo, setListInfo] = useState<ListInfo|null>(null);
  const [caption, setCaption] = useState(properties.caption)
  const {listId} = properties;

  useEffect(() => {
    if(!listId) return;

    const fetchListInfo = async () => {
      const listInfo = await getListItem(listId);
      setListInfo(listInfo);
    }

    fetchListInfo();
  }, [listId]);

  useEffect(() => {
    if(!listInfo) return;

    const handleData = (data: any[]) => {
      setItems(data || []);
    }
  
    const handleError = (error: any) => {
      console.log("Error getting document data:", error);
    }

    const unsubscribe = getItemsSnapshot(listInfo.items || [], handleData, handleError);
    return () => {
      unsubscribe();
    };
  }, [listInfo]);

  return <div className={contentStyles.stockItemWall}>
    <div className={headerStyles.sectionHeader}>{caption || 'NA'}</div>
    {items.map(item => <NewsLetterItemEx key={item.id} itemInfo={item}></NewsLetterItemEx>)}
  </div>
}

export default NewsLetterItemList;
